<template>
  <OsButton
    v-if="!isInline"
    aria-label="Login / Sign up"
    is-primary
    @click="onClick"
  >
    <OsIcon name="Person" size="1.25rem" class="text-white" />
    <span>Login / Sign up</span>
  </OsButton>
  <span v-else>login or sign up<template v-if="fullStop">.</template></span>
</template>

<script lang="ts">
export default {
  name: 'LoginButton',
  props: {
    isInline: {
      type: Boolean,
      required: false,
      default: false
    },
    fullStop: {
      type: Boolean,
      required: false,
      default: true
    },
    trackingOptions: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  setup(props) {
    const { onLogin } = useCiamLogin()
    const { $dataLayer } = useNuxtApp()

    const onClick = () => {
      onLogin()

      $dataLayer.linkClick({
        category: 'user',
        action: 'login',
        ...props.trackingOptions
      })
    }

    return {
      onClick
    }
  }
}
</script>
